<template>
    <transition name="fade-down">
        <div v-if="pwa && pwa.needRefresh" class="relative w-full bg-primary p-4 text-sm text-white">
            <div class="container relative mx-auto text-center">
                A new version of this shop is available. Please <button class="underline" @click="pwa.updateServiceWorker()">
                    reload the page
                </button> to ensure a smooth experience. Your order and checkout form will not be lost.
            </div>
        </div>
    </transition>
</template>

<script lang="ts" setup>
    // TODO: consider to change this behaviour - do not show a notice on the top
    // TODO: and then we watch the pwa.value.needRefresh and when it changes to true, we auto update if the user is idle for X
    const pwa: any = ref(undefined)
    onMounted(() => {
        pwa.value = useNuxtApp().$pwa
        setTimeout(() => {
            if (pwa.value.needRefresh) {
                pwa.value.updateServiceWorker()
            }
        }, 500)
    })
</script>
