<template>
    <div id="header" class="fixed left-0 top-0 z-50 w-full bg-white shadow-md">
        <NavBarUpdateNotice />
        <div class="container relative mx-auto flex items-center justify-between">
            <nav v-if="webshopSettings().availableLanguages.length > 1 || webshopSettings()?.global?.client?.address || webshopSettings()?.global?.client?.tel" class="relative z-50 mr-auto flex flex-1 items-center gap-2 lg:order-3 lg:ml-8 lg:mr-0 lg:flex-initial lg:gap-8">
                <ul v-if="webshopSettings().availableLanguages.length > 1 || account().getAuthEnabled" class="order-2 flex space-x-2 lg:order-1 lg:flex-row lg:items-center lg:justify-end lg:space-x-8">
                    <li v-if="account().getAuthEnabled">
                        <NavBarAccountButton />
                    </li>
                    <li v-if="webshopSettings().availableLanguages.length > 1" class="hidden lg:block">
                        <NavBarLanguageSwitch />
                    </li>
                </ul>
                <ul
                    v-if="webshopSettings()?.global?.client?.address || webshopSettings()?.global?.client?.tel"
                    class="order-1 flex space-x-2 lg:order-2 lg:flex-row lg:items-center lg:justify-end">
                    <li
                        v-if="webshopSettings()?.global?.client?.address"
                        class="flex justify-center"
                    >
                        <a
                            :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${webshopSettings()?.global?.client?.company},+${webshopSettings()?.global?.client?.address},+${webshopSettings()?.global?.client?.postalCode},+${webshopSettings()?.global?.client?.city}`)}`"
                            target="_blank"
                            class="py-4 text-center text-primary underline"
                        >
                            <Icon class="text-2xl" :icon="IconLocation" :auto-align="false" />
                        </a>
                    </li>
                </ul>
            </nav>
            <NuxtLink
                :to="localePath('/')"
                aria-label="logo"
                class="relative z-50 mx-auto flex h-16 w-60 max-w-[20vw] flex-1 items-center justify-center space-x-2 py-2 transition-opacity hover:opacity-70 lg:order-1 lg:m-0 lg:h-24 lg:max-w-full lg:flex-initial lg:justify-start lg:py-4"
                @click="mobileNav().close()">
                <NuxtImg
                    v-if="!webshopSettings().logo().includes('foodticket-logo')"
                    :src="webshopSettings().logo()"
                    class="max-h-full max-w-full"
                    alt="Logo"
                    height="64"
                    quality="100" />
                <img v-else :src="webshopSettings().logo()" class="max-h-full max-w-full" alt="Logo" height="64" />
            </NuxtLink>
            <div class="flex flex-1 items-center justify-end lg:order-2 lg:ml-auto lg:flex-initial">
                <NavBarMainNav />
                <div class="relative z-50 flex gap-4 overflow-hidden lg:hidden">
                    <NavBarLanguageSwitch v-if="webshopSettings().availableLanguages.length > 1" />
                    <NavBarHamburger />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { webshopSettings } from '~/store/webshopSettings'
    import { mobileNav } from '~/store/mobileNav'
    import NavBarAccountButton from '~/components/NavBar/NavBarAccountButton.vue'
    import NavBarLanguageSwitch from '~/components/NavBar/NavBarLanguageSwitch.vue'
    import NavBarMainNav from '~/components/NavBar/NavBarMainNav.vue'
    import NavBarHamburger from '~/components/NavBar/NavBarHamburger.vue'
    import Icon from '~/components/Icon.vue'
    import IconLocation from '@/assets/icons/location.svg'
    import NavBarUpdateNotice from '~/components/NavBar/NavBarUpdateNotice.vue'
    import { account } from '~/store/account'
    const localePath = useLocalePath()
</script>
