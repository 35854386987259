<template>
    <form class="flex flex-col items-center justify-center space-y-1 overflow-hidden text-gray-700 lg:flex-row lg:space-x-1 lg:space-y-0">
        <transition name="fade" mode="out-in">
            <Icon :key="locale" :icon="getLanguageSwitchIcon" class="mb-[0.125em] block size-[1em] overflow-hidden rounded-full text-xl" :filled="true" />
        </transition>
        <!-- eslint-disable-next-line tailwindcss/no-custom-classname -->
        <select v-model="locale" class="custom-select appearance-none overflow-hidden truncate bg-white text-xxs font-bold uppercase leading-none outline-none [text-align-last:center] lg:text-sm lg:text-current" @change="switchLang(locale)">
            <option v-for="lang in webshopSettings().availableLanguages" :key="lang.code" :value="lang.code" :selected="locale === lang.code">
                {{ lang.name }}
            </option>
        </select>
    </form>
</template>

<script lang="ts" setup>
    import { mobileNav } from '~/store/mobileNav'
    import { webshopSettings } from '~/store/webshopSettings'
    import Icon from '~/components/Icon.vue'
    import IconDe from '~/assets/icons/de.svg'
    import IconEn from '~/assets/icons/en.svg'
    import IconFr from '~/assets/icons/fr.svg'
    import IconIt from '~/assets/icons/it.svg'
    import IconNl from '~/assets/icons/nl.svg'
    const { locale } = useI18n()
    const switchLocalePath = useSwitchLocalePath()
    const getLanguageSwitchIcon = computed(() => {
        switch (locale.value) {
        case 'de':
            return IconDe
        case 'en':
            return IconEn
        case 'fr':
            return IconFr
        case 'it':
            return IconIt
        case 'nl':
            return IconNl
        default:
            return false
        }
    })
    function switchLang (locale: string) {
        // TODO: scroll to top before calling navigateTo (implement sth along these lines: https://stackoverflow.com/a/53247994)
        mobileNav().close()
        navigateTo(switchLocalePath(locale))
    }
</script>

<style lang="scss">
    .custom-select {
        background-image: url('@/assets/icons/chevron-down.svg');
        @apply bg-[length:0.8em_0.8em] bg-[right_top_0.1em] bg-no-repeat pr-3 lg:bg-[right_top_0.3em] lg:pr-4;
    }
</style>
