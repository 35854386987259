<template>
    <li>
        <NuxtLink
            :to="{ path, hash }"
            class="flex items-center justify-between text-2xl font-bold uppercase leading-none transition-opacity duration-300 lg:text-sm lg:hover:opacity-70"
            :class="{ 'text-primary': menuIsActive(path, hash) }"
            @click="mobileNav().close()">
            <span v-if="path !== '/shop' && path !== '/shop/pickup'" class="lg:flex lg:items-center lg:gap-1">
                <Icon v-if="icon" :icon="icon" class="hidden lg:block" :auto-align="false" />
                <span>{{ label }}</span>
            </span>
            <span v-else class="flex items-center gap-2 lg:gap-1">
                <span v-if="icon" class="flex items-center justify-center gap-1">
                    <Icon :icon="icon" :auto-align="false" />
                    <client-only>
                        <span
                            v-if="cart().getCartQuantity > 0"
                            class="size-6 rounded-full text-center text-base font-bold leading-6 text-white lg:block lg:size-4 lg:text-xxs lg:leading-4"
                            :class="menuIsActive(path, hash) ? 'bg-primary' : 'bg-gray-900'"
                        >
                            {{ cart().getCartQuantity }}
                        </span>
                    </client-only>
                </span>
                <span>{{ label }}</span>
            </span>
            <Icon :icon="IconChevronRight" class="block text-lg lg:hidden" />
        </NuxtLink>
    </li>
</template>

<script setup lang="ts">
    import { mobileNav } from '~/store/mobileNav'
    import Icon from '~/components/Icon.vue'
    import { cart } from '~/store/cart'
    import IconChevronRight from '~/assets/icons/chevron-right.svg'
    defineProps({
        label: String,
        icon: Object,
        path: String,
        hash: String
    })
    const localePath = useLocalePath()
    const route = useRoute()
    function menuIsActive (path: string, hash: string) {
        let isActive = false
        if (path === localePath('/')) {
            if (route.hash) {
                isActive = route.hash === hash
            } else if (!hash) {
                isActive = route.fullPath.replace(route.hash, '') === path
            }
        } else {
            isActive = route.path === path || (route.path.includes('shop') && (path.includes(route.path) || route.path.includes(path)))
        }
        return isActive
    }
</script>
