<template>
    <nav
        class="absolute left-1/2 top-0 z-40 mx-[-50vw] h-screen w-screen origin-top bg-white px-2 pt-16 transition-[opacity,transform,visibility] duration-150 lg:relative lg:left-0 lg:-mx-0 lg:size-auto lg:flex-row lg:space-x-4 lg:px-0 lg:pt-0 lg:transition-none"
        :class="!mobileNav().isOpen ? 'invisible -translate-y-4 opacity-0 lg:visible lg:flex lg:-translate-y-0 lg:opacity-100' : 'visible -translate-y-0 opacity-100 lg:flex'">
        <!-- eslint-disable-next-line tailwindcss/no-custom-classname -->
        <div class="inner-menu container mx-auto h-full overflow-y-auto pb-4 lg:flex lg:h-auto lg:items-end lg:gap-8 lg:overflow-visible lg:px-0 lg:pb-0">
            <ul class="space-y-4 border-t border-primary-800 pt-4 lg:flex lg:flex-row lg:space-x-8 lg:space-y-0 lg:border-t-0 lg:pt-0">
                <template v-for="(item, index) in navItems" :key="index">
                    <MainNavItem v-if="item.enabled" v-bind="item" />
                </template>
            </ul>
        </div>
    </nav>
</template>

<script setup lang="ts">
    import { mobileNav } from '~/store/mobileNav'
    import { getScrollBarWidth } from '~/composables/blockScroll'
    import MainNavItem from '~/components/NavBar/NavBarMainNavItem.vue'
    import { checkout } from '~/store/checkout'
    import { webshopSettings } from '~/store/webshopSettings'
    import { getDispatchTypeUrl } from '~/composables/navigateToDispatchType'
    import IconShoppingCart from '~/assets/icons/shopping-cart.svg'
    const { t } = useI18n()
    const scrollBarWidth = ref('0px')
    const isMounted = ref(false)
    const localePath = useLocalePath()
    const currentDispatchType = computed(() => {
        if (!isMounted.value) {
            return webshopSettings().getDefaultDispatchType
        } else {
            return checkout().input.dispatchType
        }
    })
    const navItems = computed(() => [
        {
            label: t('navbar.home'),
            path: localePath('/'),
            hash: '',
            enabled: true
        },
        {
            label: webshopSettings().hasDispatchTypes ? t('navbar.orderOnline') : t('menu'),
            icon: webshopSettings().hasDispatchTypes ? IconShoppingCart : null,
            path: getDispatchTypeUrl(currentDispatchType.value),
            hash: '',
            enabled: true
        },
        {
            label: t('navbar.aboutUs'),
            path: localePath('/'),
            hash: '#about',
            enabled: webshopSettings()?.filteredContentBlocks?.length
        },
        {
            label: t('navbar.contact'),
            path: localePath('/'),
            hash: '#contact',
            enabled: true
        }
    ])
    onMounted(() => {
        scrollBarWidth.value = `${getScrollBarWidth() / 2}px`
        isMounted.value = true
    })
</script>

<style lang="scss">
    .inner-menu {
        @media screen and (max-width: 740px) {
            padding-right: calc(0.75rem + v-bind(scrollBarWidth));
            padding-left: calc(0.75rem + v-bind(scrollBarWidth));
        }
    }
</style>
